// import Vue from 'vue'
import { Component } from "vue-property-decorator";
import { UserLoginModel, UserLoggedInfoModel, ChangePasswordModel, RecuperaPasswordModel } from "@/models/userModels";
import { mapActions } from "vuex";
import axios, { AxiosPromise } from "axios";
import baseContextComponent from "@/components/baseContextComponent";
import api from "@/services/api";

@Component({
	methods: { ...mapActions(["loginUser"]) }
})
export default class passwordRecoverPage extends baseContextComponent {
	isLoading: boolean = false;
	recuperata: boolean = false;
	user: UserLoginModel = new UserLoginModel();
	loginUser!: (token: any) => Promise<UserLoggedInfoModel>;
	emailRules = [
		(v: string) => {
			return !!v || "Campo obbligatorio";
		},
		(v: string | null) =>
			v == null || /.+@.+/.test(v) || "Formato non valido"
	];
	passwordRules = [(v: string | null) => !!v || "Campo obbligatorio"];

	checkForm(e: any) {
		var instance = this;

		e.preventDefault();
		instance.isLoading = true;
		var credential = new RecuperaPasswordModel();
		credential.username = instance.user.username;
		credential.isWeb = true;
		api.doRecuperaPassword(credential)
			.then(token => {
				this.recuperata = true;
				instance.isLoading = false;
			}).finally(() => instance.isLoading = false);
	}
	get headerText(): string {
		return this.$appConfig.loginPage.headerText;
	}
	get htmlText(): string {
		// return this.$appConfig.loginPage.htmlText;
		return this.recuperata ? "E' satta inviata una email all'indirizzo di posta associato all'account con le nuove credenziali" : "Inserire l'username per il quale si desidera recuperare la password";
	}
}
